import React from 'react';
import * as Icons from 'res/icons';
import { SvgTag, IconName } from 'types';

interface Props extends Omit<SvgTag, 'title'> {
	name: IconName;
	className?: string;
}

const Icon = ({ name, className, ...props }: Props) => {
	const I = Icons[name];

	return (
		<I
			title={`${name
				.replace('Ic', '')
				.split(/(?=[A-Z])/)
				.join(' ')} icon`}
			className={`fill-current ${className ?? ''}`}
			{...props}
		/>
	);
};

export default Icon;
