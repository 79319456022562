import icBullet from './images/ic_bullet.svg';
import icBulletFilled from './images/ic_bullet_filled.svg';
import icChat from './images/ic_chat.svg';
import icCheck from './images/ic_check.svg';
import icChevronDown from './images/ic_chevron_down.svg';
import icChevronLeft from './images/ic_chevron_left.svg';
import icChevronLeftThin from './images/ic_chevron_left_thin.svg';
import icChevronRight from './images/ic_chevron_right.svg';
import icChevronRightThin from './images/ic_chevron_right_thin.svg';
import icChevronUp from './images/ic_chevron_up.svg';
import icDotMenu from './images/ic_dot_menu.svg';
import icEndQuote from './images/ic_end_quote.svg';
import icLogo from './images/ic_logo.svg';
import icLogoCbx from './images/ic_logo_cbx.svg';
import icLogoCrystal from './images/ic_logo_crystal.svg';
import icLogoElectron from './images/ic_logo_electron.svg';
import icLogoJs from './images/ic_logo_js.svg';
import icLogoK8S from './images/ic_logo_k8s.svg';
import icLogoPython from './images/ic_logo_python.svg';
import icLogoSage from './images/ic_logo_sage.svg';
import icLogoSchedulePro from './images/ic_logo_schedule_pro.svg';
import icLogoSpire from './images/ic_logo_spire.svg';
import icLogoSpireFull from './images/ic_logo_spire_full.svg';
import icLogoSpireTitle from './images/ic_logo_spire_title.svg';
import icLongArrowLeft from './images/ic_long_arrow_left.svg';
import icLongArrowRight from './images/ic_long_arrow_right.svg';
import icMapPinCsi from './images/ic_map_pin_csi.svg';
import icPhone from './images/ic_phone.svg';
import icStar from './images/ic_star.svg';
import icStartQuote from './images/ic_start_quote.svg';
import ilCalendar from './images/il_calendar.png';
import plDashboard from './images/pl_dashboard.png';
import plDashboardMobile from './images/pl_dashboard_mobile.png';
import plFeaturesBg from './images/pl_features_bg.png';
import plFeaturesBgMobile from './images/pl_features_bg_mobile.png';
import plGroup from './images/pl_group.png';
import plGroupMobile from './images/pl_group_mobile.png';
import plLaptopScreen from './images/pl_laptop_screen.png';
import plLaptopScreenMobile from './images/pl_laptop_screen_mobile.png';
import plLogo from './images/pl_logo.png';
import plLogoBgLight from './images/pl_logo_bg_light.png';
import plLogoBgPrimary from './images/pl_logo_bg_primary.png';
import plMap from './images/pl_map.png';
import plMapMobile from './images/pl_map_mobile.png';
import plMapPin from './images/pl_map_pin.svg';
import plUserDefault from './images/pl_user_default.png';

const images = {
	dark: {},
	light: {},
	default: {
		icBullet,
		icBulletFilled,
		icChat,
		icCheck,
		icChevronDown,
		icChevronLeft,
		icChevronLeftThin,
		icChevronRight,
		icChevronRightThin,
		icChevronUp,
		icDotMenu,
		icEndQuote,
		icLogo,
		icLogoCbx,
		icLogoCrystal,
		icLogoElectron,
		icLogoJs,
		icLogoK8S,
		icLogoPython,
		icLogoSage,
		icLogoSchedulePro,
		icLogoSpire,
		icLogoSpireFull,
		icLogoSpireTitle,
		icLongArrowLeft,
		icLongArrowRight,
		icMapPinCsi,
		icPhone,
		icStar,
		icStartQuote,
		ilCalendar,
		plDashboard,
		plDashboardMobile,
		plFeaturesBg,
		plFeaturesBgMobile,
		plGroup,
		plGroupMobile,
		plLaptopScreen,
		plLaptopScreenMobile,
		plLogo,
		plLogoBgLight,
		plLogoBgPrimary,
		plMap,
		plMapMobile,
		plMapPin,
		plUserDefault,
	},
};

export default images;
