interface Contact {
	text: string;
	link: string;
}

export const CONTACT: Record<string, Contact> = {
	email: {
		text: 'james@csi-international.ca',
		link: 'mailto:james@csi-international.ca',
	},
	phone: {
		text: '+1 416 7264662',
		link: 'tel:+14167264662',
	},
};
