import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { ResetPasswordState } from 'store/types';

const initialState: ResetPasswordState = {
	code: '',
};

const { actions, reducer: resetPasswordReducer } = createSlice({
	name: 'resetPassword',
	initialState,
	reducers: {
		addOTP: (state, { payload }: PA<string>) => {
			state.code = payload;
		},
		resetOTP: () => initialState,
	},
});

export const resetPasswordActions = { ...actions };
export default resetPasswordReducer;
