import { RootState, useTypedSelector } from '.';
import { Request } from 'lib/namespaces';
import { Roles } from 'lib/util';
import Profile from 'models/Profile';
import { UserState } from './user';
import { AuthenticationState, ResetPasswordState } from './types';

//! Selector hooks
export function useSelectState() {
	return useTypedSelector<RootState>((state) => state);
}

export function useRequestState() {
	return useTypedSelector<Request.State>(({ request }) => request);
}

export function useAuthenticationState() {
	return useTypedSelector<AuthenticationState>(({ authentication }) => authentication);
}

export function useUserState() {
	return useTypedSelector<UserState>(({ user }) => user);
}

export function useProfileState() {
	return useTypedSelector<Profile>(({ profile }) => profile);
}

export function useResetPasswordState() {
	return useTypedSelector<ResetPasswordState>(({ resetPassword }) => resetPassword);
}

export function useAuthorizationState() {
	const { roleId: role } = useUserState();

	return {
		role,
		isSuperAdmin: role === Roles.SUPER_ADMIN,
	};
}
