import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Image } from 'lib/components';
import { PageLayout } from 'lib/components/Layout';
import { SPIRE_LINKS } from 'lib/constants';

const SpireAboutScreen = () => {
	return (
		<PageLayout noContactUs title="Spire" className="pt-20">
			<Box className="flex items-center justify-center w-full h-80 bg-primary-500">
				<Image src="icLogoSpireFull" className="" alt="Spire Systems logo" />
			</Box>
			<Box className="w-11/12 mx-auto mt-10">
				<Typography variant="h2" className="text-3xl font-bold text-center xs:text-4xl font-title">
					About Spire Systems
				</Typography>
				<Box className="w-full max-w-4xl mx-auto mt-6">
					<Typography variant="body1" className="text-center">
						<span className="font-semibold">Spire Systems Inc.</span>{' '}
						{
							'creates business management software for small and mid-sized companies, enabling them to streamline operations, gain better insight and maximize their business potential.'
						}
						<br />
						<br />
						{
							'Our software gives businesses the flexibility and scalability to differentiate from the competition. Designed to automate business processes and optimize inventory control, our solution will help cut costs, improve margins and meet customer demand.'
						}
					</Typography>
				</Box>
				<Box className="w-full mt-8 sm:flex sm:space-x-6 sm:justify-center sm:flex-row sm:items-center">
					<Button
						variant="outlined"
						href={SPIRE_LINKS.website}
						target="_blank"
						className="w-full sm:w-60 h-14"
						title="Spire Systems website"
					>
						Learn More
					</Button>
					<Button
						href={SPIRE_LINKS.demo}
						target="_blank"
						className="w-full mt-6 sm:w-60 h-14 sm:mt-0"
						title="Request a demo"
					>
						Request a demo
					</Button>
				</Box>
				<Box className="w-full mt-10 sm:max-w-lg sm:mx-auto lg:max-w-none lg:flex lg:items-center xl:space-x-16 lg:space-x-6 lg:mt-16">
					<Box className="w-full lg:w-5/12 xl:w-4/12">
						<Typography
							variant="h2"
							className="text-3xl font-semibold xs:text-4xl lg:text-lg xl:text-2xl 2xl:text-4xl font-title"
						>
							{'Gain visibility, streamline processes and take control of your business.'}
						</Typography>
						<Typography variant="body1" className="mt-5">
							{
								'Spire gives you a competitive advantage by providing the business tools you need to make better decisions and increase profitability.'
							}
						</Typography>
						<Button
							href={SPIRE_LINKS.feature}
							target="_blank"
							className="w-full mt-6 sm:w-60 h-14"
							title="Spire Systems' features"
						>
							Features
						</Button>
					</Box>
					<Box className="w-full mt-8 lg:w-7/12 xl:w-8/12 lg:mt-0">
						<Image src="plLaptopScreenMobile" className="w-full lg:hidden" alt="Laptop screen" />
						<Image src="plLaptopScreen" className="hidden w-full lg:block" alt="Laptop screen" />
					</Box>
				</Box>
			</Box>
		</PageLayout>
	);
};

export default SpireAboutScreen;
