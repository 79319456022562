import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
	title?: string;
}

const Head = (props: Props) => {
	const addSuffix = React.useCallback((title?: string) => {
		return title ? `${`${title} | CSI International`}` : 'CSI International';
	}, []);

	return <Helmet title={addSuffix(props.title)} />;
};

export default Head;
