import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, DeviceTypes } from 'lib/util';
import SignInRequest from 'network/requests/SignInRequest';
import ForgotPasswordRequest from 'network/requests/ForgotPasswordRequest';
import ResetPasswordRequest from 'network/requests/ResetPasswordRequest';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import OkResponse from 'network/responses/OkResponse';
import { requestActions } from '../request';

const signIn = createAsyncThunk('authentication/sign-in', async (payload: SignInRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(signIn.typePrefix));
	try {
		const response = await API.post<SignInRequest, AuthenticationResponse>('/auth/sign-in', {
			...payload,
			deviceType: DeviceTypes.WEB,
		});

		thunkApi.dispatch(requestActions.beforeFulfilled(signIn.typePrefix));
		return response.data;
	} catch (error: any) {
		thunkApi.dispatch(requestActions.beforeRejected(signIn.typePrefix));
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const forgotPassword = createAsyncThunk(
	'authentication/forgot-password',
	async (payload: ForgotPasswordRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(forgotPassword.typePrefix));
		try {
			const response = await API.post<ForgotPasswordRequest, OkResponse>('/auth/forgot-password', payload);

			thunkApi.dispatch(requestActions.beforeFulfilled(forgotPassword.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(forgotPassword.typePrefix));
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const resetPassword = createAsyncThunk(
	'authentication/reset-password',
	async (payload: ResetPasswordRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(resetPassword.typePrefix));
		try {
			const response = await API.post<ResetPasswordRequest, AuthenticationResponse>('/auth/reset-password', {
				...payload,
				deviceType: DeviceTypes.WEB,
			});

			thunkApi.dispatch(requestActions.beforeFulfilled(resetPassword.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(resetPassword.typePrefix));
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signOut = createAsyncThunk('authentication/sign-out', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started('authentication/sign-out'));
	try {
		const response = await API.get<any, OkResponse>('/auth/sign-out');

		thunkApi.dispatch(requestActions.beforeFulfilled('authentication/sign-out'));
		return response.data;
	} catch (error: any) {
		thunkApi.dispatch(requestActions.beforeRejected('authentication/sign-out'));
		return thunkApi.rejectWithValue({ error });
	}
});

const authenticationAsyncActions = {
	signIn,
	signOut,
	forgotPassword,
	resetPassword,
};
export default authenticationAsyncActions;
