import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import Profile from 'models/Profile';
import User from 'models/User';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import ErrorResponse from 'network/responses/ErrorResponse';
// import FileResponse from 'network/responses/FileResponse';
import authenticationAsyncActions from '../authentication/authentication.thunk';
import profileAsyncActions from './profile.thunk';
import userAsyncActions from './user.thunk';
import { Request } from 'lib/namespaces';
import { CPA } from '../types';

export interface ProfileState extends Profile {}

const initialState: ProfileState = {
	id: '',
	firstName: null,
	lastName: null,
	phoneCountryCode: null,
	phoneNumber: null,
	birthdate: null,
	profilePicture: null,
	coverPicture: null,
	phoneNumberVerifiedAt: null,
};

const fillAuth = (state: WritableDraft<ProfileState>, action: CPA<AuthenticationResponse>) => {
	state.id = action.payload.user.profile.id;
	state.firstName = action.payload.user.profile.firstName;
	state.lastName = action.payload.user.profile.lastName;
	state.phoneCountryCode = action.payload.user.profile.phoneCountryCode;
	state.phoneNumber = action.payload.user.profile.phoneNumber;
	state.birthdate = action.payload.user.profile.birthdate;
	state.profilePicture = action.payload.user.profile.profilePicture;
	state.coverPicture = action.payload.user.profile.coverPicture;
	state.phoneNumberVerifiedAt = action.payload.user.profile.phoneNumberVerifiedAt;
};

const slice = createSlice({
	name: 'profile',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[authenticationAsyncActions.signIn.fulfilled.type]: fillAuth,
		[userAsyncActions.update.fulfilled.type]: (state, action: CPA<User>) => action.payload.profile,
		[profileAsyncActions.update.fulfilled.type]: (state, action: CPA<Profile>) => {
			state = action.payload;
			Request.postRequest(action);
		},
		[profileAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			Request.postErrorRequest(state, action, initialState),
		// [profileAsyncActions.updatePicture.fulfilled.type]: (state, action: CPA<FileResponse & { typeId: number }>) => {
		// 	if (action.payload.typeId === 0) {
		// 		state.profilePicture = action.payload.path;
		// 	} else if (action.payload.typeId === 1) {
		// 		state.coverPicture = action.payload.path;
		// 	}

		// 	Request.postRequest(action);
		// },
		// [profileAsyncActions.updatePicture.rejected.type]: (state, action: CPA<ErrorResponse>) =>
		// 	Request.postErrorRequest(state, action, initialState),
	},
});

export const profileActions = slice.actions;

export default slice.reducer;
