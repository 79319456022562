import React from 'react';
import { DivTag } from 'types';

interface Props extends Omit<DivTag, 'ref' | 'className'> {
	children?: React.ReactNode;
	className?: string;
}

const Screen = React.forwardRef<HTMLDivElement, Props>(function Screen({ className, children, ...props }, ref) {
	return (
		<div ref={ref} className={`w-auto h-auto ${className ?? ''}`} {...props}>
			{children}
		</div>
	);
});

export default Screen;
