import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import authentication from './authentication';
import user from './user';
import profile from './user/profile.slice';
import resetPassword from './resetPassword';
import request from './request';

const reducer = combineReducers({
	request,
	resetPassword,
	authentication,
	user,
	profile,
});

const preloadedState = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')!) : {};

// This middleware will just add the property "async dispatch" to all actions
// @ts-ignore
const asyncDispatchMiddleware = (store) => (next) => (action) => {
	let syncActivityFinished = false;
	let actionQueue: Array<any> = [];

	function flushQueue() {
		actionQueue.forEach((a) => store.dispatch(a)); // flush queue
		actionQueue = [];
	}

	function dispatch(asyncAction: any) {
		actionQueue = actionQueue.concat([asyncAction]);

		if (syncActivityFinished) {
			flushQueue();
		}
	}

	const actionWithAsyncDispatch = Object.assign({}, action, { dispatch });

	const res = next(actionWithAsyncDispatch);

	syncActivityFinished = true;
	flushQueue();

	return res;
};

const store = configureStore({
	reducer,
	preloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncDispatchMiddleware),
	devTools: process.env.NODE_ENV === 'development',
});

store.subscribe(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { request, ...state } = store.getState();
	localStorage.setItem('state', JSON.stringify(state));
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
