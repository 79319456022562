import ErrorResponse from 'network/responses/ErrorResponse';
import { toast } from 'react-hot-toast';
import { authenticationAsyncActions } from 'store/authentication';
import { requestActions } from 'store/request';
import { CPA } from 'store/types';

namespace Request {
	export enum Status {
		PENDING = 'pending',
		BEFORE_FULFILLED = 'before-fulfilled',
		FULFILLED = 'fulfilled',
		BEFORE_REJECTED = 'before-rejected',
		REJECTED = 'rejected',
	}

	export interface Payload {
		[key: string]: string | number | boolean | object;
	}

	export interface Info {
		name: string;
		status: Status;
		message: string;
		payload: Payload;
	}

	export interface State {
		updatedAt: number;
		list: Array<Info>;
	}

	export const postRequest = (action: CPA<any>) => {
		action.dispatch(
			requestActions.fulfilled({
				name: action.type,
				message: '',
				payload: {},
			})
		);
	};

	export const postErrorRequest = (
		state: any,
		action: CPA<ErrorResponse>,
		initialState: any,
		displayError: boolean = true
	) => {
		if (displayError) {
			toast.error(action.payload.error.list[0].msg);
		}

		if (action.payload.error.status === 401) {
			action.dispatch(authenticationAsyncActions.signOut());
			state = initialState;
		}

		action.dispatch(
			requestActions.rejected({
				name: action.type,
				message: '',
				payload: { ...action.payload.error },
			})
		);

		return state;
	};
}

export default Request;
