// THIS IS A GENERATED FILE. DO NOT EDIT.

const colors = {
	primary: {
		light: '#7d6a93',
		main: '#26064b',
		dark: '#17042d',
	},
	secondary: {
		light: '#6b9bc8',
		main: '#0859a4',
		dark: '#053562',
	},
	error: {
		light: '#fc6666',
		main: '#fa0000',
		dark: '#960000',
	},
	warning: {
		light: '#fde047',
		main: '#eab308',
		dark: '#a16207',
	},
	info: {
		light: '#5eead4',
		main: '#14b8a6',
		dark: '#0f766e',
	},
	success: {
		light: '#86efac',
		main: '#22c55e',
		dark: '#15803d',
	},
	lighterShade: {
		light: '#d8dadb',
		main: '#bec1c3',
		dark: '#727475',
	},
	lightShade: {
		light: '#b4b6ba',
		main: '#82868c',
		dark: '#4e5054',
	},
	shade: {
		light: '#828489',
		main: '#2e323a',
		dark: '#1c1e23',
	},
};

export default colors;
