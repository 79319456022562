import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { toast } from 'react-hot-toast';
import { LS } from '@cyboticx/common';
import { API, DeviceTypes } from 'lib/util';
import authenticationAsyncActions from './authentication.thunk';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import ErrorResponse from 'network/responses/ErrorResponse';
import { Request } from 'lib/namespaces';
import { requestActions } from '../request';
import { AuthenticationState, CPA } from '../types';
import userAsyncActions from '../user/user.thunk';
import OkResponse from 'network/responses/OkResponse';

const initialState: AuthenticationState = {
	isAuthenticated: false,
	accessToken: '',
	expiryAt: -1,
	deviceType: DeviceTypes.WEB,
};

const fillState = (state: WritableDraft<AuthenticationState>, action: CPA<AuthenticationResponse>) => {
	state.isAuthenticated = true;
	state.accessToken = action.payload.accessToken;
	state.expiryAt = action.payload.expiryAt;
	state.deviceType = action.payload.deviceType;

	LS.addAccessToken(action.payload.accessToken);
	API.addAccessToken(action.payload.accessToken);

	Request.postRequest(action);
};

const handleSignOut = (state: WritableDraft<AuthenticationState>, action: CPA<OkResponse>) => {
	state.isAuthenticated = false;
	state.accessToken = '';
	state.expiryAt = -1;

	LS.removeAccessToken();
	API.removeAccessToken();

	Request.postRequest(action);
};

const { actions, reducer: authenticationReducer } = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		restoreAccessToken: (state) => {
			API.addAccessToken(state.accessToken === '' ? undefined : state.accessToken);
		},
		removeAuthState: () => {
			API.removeAccessToken();
			return initialState;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: fillState,
		[authenticationAsyncActions.signIn.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			Request.postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.rejected.type]: handleSignOut,
		[authenticationAsyncActions.signOut.fulfilled.type]: handleSignOut,
		[userAsyncActions.refreshUser.rejected.type]: (state, { payload }: PA<ErrorResponse>) => {
			if (payload.error.status === 401) {
				state = initialState;
				LS.removeAccessToken();
				API.removeAccessToken();
			}
		},
		[authenticationAsyncActions.forgotPassword.fulfilled.type]: (_, action: CPA) => {
			toast.success('Password reset email sent.');
			Request.postRequest(action);
		},
		[authenticationAsyncActions.forgotPassword.rejected.type]: (_, { payload, dispatch }: CPA<ErrorResponse>) => {
			// TODO Update later to use languages
			const message = payload.error.list[0].msg;
			if (message === 'invalid_email') toast.error('Invalid email.');
			else toast.error('Error sending email.');

			dispatch(
				requestActions.rejected({
					name: authenticationAsyncActions.forgotPassword.typePrefix,
					message,
					payload: { ...payload.error },
				})
			);
		},
		[authenticationAsyncActions.resetPassword.fulfilled.type]: (state, action: CPA<AuthenticationResponse>) => {
			toast.success('Password reset successful.');
			fillState(state, action);
		},
		[authenticationAsyncActions.resetPassword.rejected.type]: (_, { payload, dispatch }: CPA<ErrorResponse>) => {
			// TODO Update later to use languages
			const message = payload.error.list[0].msg;
			if (message === 'invalid_code') {
				toast.error('Invalid code.');
			} else if (message === 'unknown_user') toast.error('Sorry, we do not recognize that account.');
			else if (message === 'passwords_mismatch') toast.error('Passwords mismatch. Try again.');
			else toast.error('Error resetting password. Try again.');

			dispatch(
				requestActions.rejected({
					name: authenticationAsyncActions.resetPassword.typePrefix,
					message,
					payload: { ...payload.error },
				})
			);
		},
	},
});

export const authenticationActions = actions;
export { authenticationAsyncActions };
export default authenticationReducer;
