import { createTheme, PaletteOptions } from '@mui/material/styles';
import components from './components';
import R from 'res';

const theme = createTheme({
	palette: {
		...R.colors,
	} as PaletteOptions, // Correctly defining palette as PaletteOptions
	typography: {
		fontFamily: '"Poppins", sans-serif',
	},
	breakpoints: {
		values: {
			xss: 0,
			xs: 480,
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
			'2xl': 1536,
		},
	},
	components,
});

export * from './makeStyles';
export default theme;
