import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import User from 'models/User';
import ChangePasswordRequest from 'network/requests/ChangePasswordRequest';
import UpdateUserRequest from 'network/requests/UpdateUserRequest';
import OkResponse from 'network/responses/OkResponse';
import { requestActions } from '../request';

const refreshUser = createAsyncThunk('user/refresh', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(refreshUser.typePrefix));
	try {
		const response = await API.get<any, User>('/user');

		thunkApi.dispatch(requestActions.beforeFulfilled(refreshUser.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(refreshUser.typePrefix));
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('user/update', async (payload: UpdateUserRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));

	try {
		const response = await API.client.put<UpdateUserRequest, AxiosResponse<User>>('/user', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));

		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const changePassword = createAsyncThunk('user/change-password', async (payload: ChangePasswordRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(changePassword.typePrefix));

	try {
		const response = await API.client.post<ChangePasswordRequest, AxiosResponse<OkResponse>>(
			'/user/change-password',
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(changePassword.typePrefix));

		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(changePassword.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const userAsyncActions = {
	refreshUser,
	update,
	changePassword,
};
export default userAsyncActions;
