import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import Profile from '../../models/Profile';
// import User from '../../models/User';
// import UpdateProfilePictureRequest from '../../network/requests/UpdateProfilePictureRequest';
import UpdateProfileRequest from '../../network/requests/UpdateProfileRequest';
// import FileResponse from '../../network/responses/FileResponse';
import { requestActions } from '../request';

const update = createAsyncThunk('profile/update', async (payload: UpdateProfileRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));

	try {
		const response = await API.client.put<UpdateProfileRequest, AxiosResponse<Profile>>('/profile', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		return thunkApi.rejectWithValue({ payload, error });
	}
});

// const updatePicture = createAsyncThunk(
// 	'profile/update-picture',
// 	async (payload: UpdateProfilePictureRequest, thunkApi) => {
// 		thunkApi.dispatch(requestActions.started(updatePicture.typePrefix));

// 		const formData = new FormData();
// 		formData.append('file', {
// 			uri: `file://${payload.photo}`,
// 			type: 'image/jpg',
// 			name: payload.photo.split('/').pop(),
// 		});

// 		try {
// 			const response = await API.client.post<never, AxiosResponse<FileResponse>>(
// 				`/profile/${payload.typeId}/upload`,
// 				formData,
// 				{
// 					transformRequest: () => {
// 						// !!! override data to return formData
// 						// since axios converts that to string
// 						return formData;
// 					},
// 				}
// 			);

// 			thunkApi.dispatch(requestActions.beforeFulfilled(updatePicture.typePrefix));

// 			return {
// 				typeId: payload.typeId,
// 				...response.data,
// 			};
// 		} catch (error) {
// 			thunkApi.dispatch(requestActions.beforeRejected(updatePicture.typePrefix));
// 			console.log(error);
// 			return thunkApi.rejectWithValue({ payload, error });
// 		}
// 	}
// );

const profileAsyncActions = {
	update,
	// updatePicture,
};

export default profileAsyncActions;
