import images from './images';
import strings from './strings';
import languages from './languages';
import colors from './colors';

const R = {
	images,
	strings,
	languages,
	colors,
};

export default R;
