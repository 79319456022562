/**
 * Generate tab objects for a page
 * @remarks
 * For creating tabs in a page,
 */
namespace Paths {
	export interface Path {
		value: string;
		label: string;
		url: string;
	}

	/**
	 * List of {@link Path | Tab paths} for a specific page
	 * @typeParam T - Enum of tab names for page
	 */
	export type PathList<T> = Record<keyof T, Path>;

	/**
	 *
	 * @typeParam T - Enum of tab names for page
	 * @param url - The url of the current page
	 * @returns PathList
	 */
	export const list = <T>(type: T, url: string = ''): PathList<T> => {
		// @ts-ignore
		return Object.values(type).reduce<PathList<T>>((main, value: string) => {
			const path: Path = {
				value: value.toLowerCase(),
				label: value.replace('_', ' '),
				url: `${url}/${value.toLowerCase()}`,
			};

			return {
				...main,
				[value.toUpperCase()]: path,
			};
		}, {} as PathList<T>);
	};

	/**
	 * Hook to get paths for a page from specific enum
	 * defined for the page
	 * @typeParam T - Enum of tab names for page
	 * @param type - The enum of tab names for the page
	 * @param url - The url of the current page
	 *
	 * @example Settings page
	 * ```ts
	 * // Enum for tabs
	 * enum Settings {
	 *   GENERAL = 'General',
	 *   PREFERENCES = 'Preferences',
	 *   PRIVACY = 'Privacy',
	 * }
	 *
	 * const { url } = useRouteMatch();
	 * const { paths, pathsArray } = Paths.usePaths(Settings, url);
	 *
	 * ```
	 */
	export const usePaths = <T>(type: T, active: keyof T, url = '') => {
		const paths = list(type, url);
		const pathsArray = Object.values(paths) as Path[];
		return { paths, pathsArray };
	};
}

export default Paths;
