import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import User from 'models/User';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import ErrorResponse from 'network/responses/ErrorResponse';
import { Request } from 'lib/namespaces';
import { CPA } from '../types';
import userAsyncActions from './user.thunk';
import authenticationAsyncActions from '../authentication/authentication.thunk';
import OkResponse from 'network/responses/OkResponse';

export interface UserState extends Omit<User, 'profile'> {}

const initialState: UserState = {
	id: '',
	email: '',
	username: '',
	roleId: -1,
	genderId: -1,
	createdAt: '',
	updatedAt: '',
};

const fillAuth = (state: WritableDraft<typeof initialState>, action: CPA<AuthenticationResponse>) => {
	state.id = action.payload.user.id;
	state.email = action.payload.user.email;
	state.roleId = action.payload.user.roleId;
	state.createdAt = action.payload.user.createdAt;
	state.updatedAt = action.payload.user.updatedAt;

	Request.postRequest(action);
};

const fillUser = (state: WritableDraft<typeof initialState>, action: CPA<User>) => {
	state.id = action.payload.id;
	state.email = action.payload.email;
	state.roleId = action.payload.roleId;
	state.createdAt = action.payload.createdAt;
	state.updatedAt = action.payload.updatedAt;

	Request.postRequest(action);
};

const { reducer: userReducer } = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: fillAuth,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[userAsyncActions.refreshUser.fulfilled.type]: fillUser,
		[userAsyncActions.refreshUser.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			Request.postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.resetPassword.fulfilled.type]: fillAuth,
		[userAsyncActions.update.fulfilled.type]: (state, action: CPA<User>) => {
			state.id = action.payload.id;
			state.email = action.payload.email;
			state.roleId = action.payload.roleId;
			state.genderId = action.payload.genderId;
			state.createdAt = action.payload.createdAt;
			state.updatedAt = action.payload.updatedAt;

			Request.postRequest(action);
		},
		[userAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			Request.postErrorRequest(state, action, initialState),
		[userAsyncActions.changePassword.fulfilled.type]: (_, action: CPA<OkResponse>) => Request.postRequest(action),
		[userAsyncActions.changePassword.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			Request.postErrorRequest(state, action, initialState),
	},
});

export default userReducer;
