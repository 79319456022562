import React from 'react';
import ReactDOM from 'react-dom';
// import { LocalizationProvider } from '@mui/lab';
// import AdapterMoment from '@mui/lab/AdapterMoment';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Bootstrap from 'Bootstrap';
import store from 'store';
import theme from 'theme';
import reportWebVitals from './reportWebVitals';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style/index.scss';

export const muiCache = createCache({
	key: 'mui',
	prepend: true,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<CacheProvider value={muiCache}>
				{/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Toaster
						toastOptions={{
							className: 'border border-light-100',
						}}
					/>
					<Bootstrap />
				</ThemeProvider>
				{/* </LocalizationProvider> */}
			</CacheProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
