export enum Roles {
	SUPER_ADMIN = 1,
	ADMIN = 2,
	USER = 4,
	BANNED = 100,
}

export enum Genders {
	UNISEX = 0,
	MALE = 1,
	FEMALE = 2,
}

export enum DeviceTypes {
	ANDROID = 'android',
	IOS = 'ios',
	WEB = 'web',
}
