import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

const components: Components<Theme> = {
	MuiButton: {
		styleOverrides: {
			root: {
				textTransform: 'none',
				fontFamily: '"Poppins", sans-serif',
				fontWeight: '600',
				borderRadius: '9999px',
			},
			contained: {
				boxShadow: 'none',
				'&:active': {
					boxShadow: 'none',
				},
			},
		},
		defaultProps: {
			color: 'primary',
			variant: 'contained',
		},
	},
};

export default components;
