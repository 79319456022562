import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { Icon, Image } from 'lib/components';
import { CONTACT } from 'lib/constants';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ScrollingProvider, useScrollSection } from 'react-scroll-section';
import Head from './Head';
import Screen from './Screen';

interface Props {
	title: string;
	children?: React.ReactNode;
	showSpireLink?: boolean;
	className?: string;
	noContactUs?: boolean;
}

interface Menu {
	label: string;
	onClick: () => void;
	selected: boolean;
}

const PageLayout = (props: Props) => {
	const { push } = useHistory();
	const { pathname } = useLocation();

	const isHome = pathname === '/';

	const MENU_ITEMS: Menu[] = ['home', 'contact'].map((label) => ({
		label,
		...useScrollSection(label),
	}));

	const scrollTo = React.useCallback((id: string) => {
		const section = document.querySelector(`#${id}`);
		section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}, []);

	return (
		<ScrollingProvider scrollBehavior="smooth" offset={200}>
			<Head title={props.title} />
			<AppBar
				position="fixed"
				sx={{
					width: '100%',
					borderBottom: `1px solid #fff`,
				}}
				className="h-20 bg-white"
				elevation={0}
			>
				<Toolbar className={`flex items-center justify-between w-11/12 mx-auto h-full`}>
					<Image
						src="plLogo"
						className="w-20 cursor-pointer"
						alt="CSI International logo"
						onClick={() => push('/')}
					/>
					{!props.noContactUs && (
						<Button
							title="Contact us"
							className="h-10 px-6 text-sm"
							onClick={() => isHome && scrollTo(MENU_ITEMS[1].label)}
						>
							Contact us
						</Button>
					)}
				</Toolbar>
			</AppBar>
			<Screen className={`overflow-y-auto bg-white ${props.className}`}>
				{props.children}
				<Box className="w-11/12 py-10 mx-auto mt-12 lg:mt-16">
					{/* Mobile */}
					<Box className="lg:hidden">
						<Box className="flex justify-center w-full">
							<Icon name="IcChat" className="w-10 h-10 text-primary-500" />
						</Box>
						<Typography variant="body1" className="mt-4 text-center text-primary-500">
							Contact us directly:
						</Typography>
						<Box className="flex justify-center w-full mt-2 ">
							<Button variant="text" href={CONTACT.email.link} className="text-primary-500">
								{CONTACT.email.text}
							</Button>
						</Box>
						<Box className="flex justify-center w-full mt-16">
							<Icon name="IcPhone" className="w-10 h-10 text-primary-500" />
						</Box>
						<Typography variant="body1" className="mt-4 text-center text-primary-500">
							You can also call us:
						</Typography>
						<Box className="flex justify-center w-full mt-2 ">
							<Button variant="text" href={CONTACT.phone.link} className="text-primary-500">
								{CONTACT.phone.text}
							</Button>
						</Box>
						{props.showSpireLink && (
							<Box className="flex justify-center w-full mt-16">
								<Button
									variant="outlined"
									href="/spire"
									className="px-0 border-t-0 border-b-2 border-solid rounded-none border-primary-500 text-primary-500 border-x-0 hover:border-t-0 hover:border-x-0 hover:border-b-2"
								>
									Interested in Spire?
								</Button>
							</Box>
						)}
					</Box>
					{/* Desktop */}
					<Box className="items-center justify-between hidden lg:flex">
						<Box className="flex items-center space-x-4">
							<Box className="flex items-center justify-center">
								<Icon name="IcChat" className="w-6 h-6 text-primary-500" />
							</Box>
							<Box className="flex items-center space-x-2">
								<Typography variant="body1" className="text-primary-500">
									Contact us directly:
								</Typography>
								<a
									href={CONTACT.email.link}
									className="font-semibold no-underline cursor-pointer text-primary-500"
								>
									{CONTACT.email.text}
								</a>
							</Box>
						</Box>
						<Box className="">
							{props.showSpireLink && (
								<Button
									variant="outlined"
									href="/spire"
									className="px-0 border-t-0 border-b-2 border-solid rounded-none border-primary-500 text-primary-500 border-x-0 hover:border-t-0 hover:border-x-0 hover:border-b-2"
								>
									Interested in Spire?
								</Button>
							)}
						</Box>
						<Box className="flex items-center space-x-4">
							<Box className="flex items-center space-x-2">
								<Typography variant="body1" className="text-primary-500">
									You can also call us:
								</Typography>
								<a
									href={CONTACT.phone.link}
									className="font-semibold no-underline cursor-pointer text-primary-500"
								>
									{CONTACT.phone.text}
								</a>
							</Box>
							<Box className="flex items-center justify-center">
								<Icon name="IcPhone" className="w-6 h-6 text-primary-500" />
							</Box>
						</Box>
					</Box>
				</Box>
			</Screen>
		</ScrollingProvider>
	);
};

export default PageLayout;
