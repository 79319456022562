import React from 'react';
import R from 'res';
import { LightVariantImage, DarkVariantImage, DefaultVariant, ImageSource } from 'types';

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet'> & {
	className?: string;
	src: ImageSource;
};

interface AvatarProps extends Props {
	size: number | string;
}

export const Avatar = React.forwardRef<HTMLImageElement, AvatarProps>(function Avatar({ size, ...props }, ref) {
	return (
		<Image
			style={{ width: size, height: size, borderRadius: '50%', verticalAlign: 'middle' }}
			{...props}
			ref={ref}
		/>
	);
});

const Image = React.forwardRef<HTMLImageElement, Props>(function Image({ src, className, ...props }, ref) {
	let image = '';

	if (!src.startsWith('http') && !src.startsWith('data')) {
		const [variant, filename] = src.split('.');
		if (variant === 'dark') {
			image = R.images.dark[filename as DarkVariantImage];
		} else if (variant === 'light') {
			image = R.images.light[filename as LightVariantImage];
		} else {
			image = R.images.default[src as DefaultVariant];
		}

		return <img src={image} className={className} {...props} ref={ref} />;
	}

	return <img src={src} className={className} {...props} ref={ref} />;
});

export default Image;
